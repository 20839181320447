.App {
  text-align: center;
}

.footer {
  padding-bottom: 2rem;
  position: fixed;
  bottom: 0;
  width: 100%;
}
